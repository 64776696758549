import isEmpty from 'lodash.isempty';
import _get from 'lodash.get';
import { decodingContent } from '../../utils/decodingContent';
import { generateSectionIdSlug, getSectionId } from '../../utils/get-section-id';

export const TOC_DATA_PDP = 'product';
export const TOC_DATA_VIDEOCENTER = 'videocenter';
export const TOC_DATA_CONTENTPAGE = 'contentpage';

/**
 * returns anchor or title of current item for table of Content
 * if titleInAnchor is false, null will be returned
 *
 * @param {Object} item current item
 */
function getAnchorName(item) {
  if (!item.titleInAnchor) {
    return null;
  }

  return decodingContent(item.anchor || item.title);
}

/**
 * loops over all titles and returns the previous available title when current item has none
 * if there is no previous title for the current item, the page title is set as anchor-title
 *
 * @param {Number} index
 * @param {Array} allTitles is an array of all titles
 * @param {String} headline  is the title of page
 * @param {Number} contentIndex  first given index
 * @param {Boolean} isName  is the title of page
 */
function getPreviousAnchor(index, allTitles, headline, contentIndex, isName) {
  if (index === 0) return headline;

  if (!isEmpty(allTitles[index - 1]) && !isName) {
    return `${allTitles[index - 1]}-${contentIndex || index}`;
  }

  if (!isEmpty(allTitles[index - 1]) && isName) {
    return `${allTitles[index - 1]}`;
  }

  return getPreviousAnchor(index - 1, allTitles, headline, index, isName);
}

export function convertItems(isPreview, translations, type, data, headline) {
  if (isEmpty(translations)) {
    return null;
  }

  switch (type) {
    case TOC_DATA_VIDEOCENTER:
      return data
        .filter((c) => isPreview || !isEmpty(c.videos))
        .map((item) => ({
          id: `videochannel-${generateSectionIdSlug(item.title)}`,
          name: item.title,
        }));
    case TOC_DATA_CONTENTPAGE: {
      const allTitles = data.map((titles) => getAnchorName(titles));

      return data.map((item, i) => {
        const newId = generateSectionIdSlug(
          item.title || item.anchor
            ? getSectionId(item.title, item.anchor)
            : getPreviousAnchor(i, allTitles, generateSectionIdSlug(headline)),
        );

        return {
          id: newId,
          name: !isEmpty(getAnchorName(item))
            ? allTitles[i]
            : getPreviousAnchor(i, allTitles, headline, null, true),
          titleInAnchor: !!item.titleInAnchor,
        };
      });
    }
    case TOC_DATA_PDP:
      return PRODUCT_DETAIL_PATHS_TO_KEYS.filter((id) => {
        const allProducts = {
          ...data.product,
          // alternativeProducts: { ...data.alternativeProducts },
        };

        const element = _get(allProducts, id);
        const isBool = typeof element === 'boolean';

        return isBool ? element : !isEmpty(element);
      }).map((item) => ({
        id: `product-${ACCORDION_ID_TO_STATE_NAME[PRODUCT_DETAIL_KEYS_TO_ID[item]]}`,
        name: translations[PRODUCT_DETAIL_KEYS_TO_ID[item]],
      }));

    default:
      return null;
  }
}

// absolute paths in product-object
const PRODUCT_DETAIL_PATHS_TO_KEYS = [
  'gallery.list',
  'articles.articlesList',
  'additionalInformation.list',
  'technicalDataList.list',
  'applicationPurposes.list',
  'characteristics.list',
  'scopeOfDelivery.included',
  'scopeOfDelivery.notIncluded',
  'documents.list',
  'spareParts.list',
  'accessories.list',
  'canBeCombinedWith.list',
  'alternativeProducts',
];

// map absolute paths to translation keys
const PRODUCT_DETAIL_KEYS_TO_ID = {
  'additionalInformation.list': 'web20_product_basic_info',
  'documents.list': 'web20_product_files_assets',
  'spareParts.list': 'web20_products_spare_parts',
  'characteristics.list': 'web20_product_characteristics',
  'accessories.list': 'web20_products_accessoires',
  'articles.articlesList': 'web20_products_articles',
  'canBeCombinedWith.list': 'web20_product_complementary_products',
  'technicalDataList.list': 'web20_product_technical_info',
  'applicationPurposes.list': 'web20_product_application_purpose',
  'scopeOfDelivery.included': 'web20_product_scope_of_delivery',
  'scopeOfDelivery.notIncluded': 'web20_product_to_order_additionally',
  alternativeProducts: 'web20_product_alternative_products',
  'gallery.list': 'web20_product_technical_drawings',
};

// map ids of accordion items to state name on pdp
export const ACCORDION_ID_TO_STATE_NAME = {
  web20_products_articles: 'detail',
  web20_product_basic_info: 'additionalInformation',
  web20_product_technical_info: 'technicalDataList',
  web20_product_application_purpose: 'applicationPurposes',
  web20_product_characteristics: 'characteristics',
  web20_product_scope_of_delivery: 'scopeOfDelivery',
  web20_product_to_order_additionally: 'notIncluded',
  web20_product_files_assets: 'documents',
  web20_products_spare_parts: 'spareParts',
  web20_products_accessoires: 'accessoires',
  web20_product_complementary_products: 'complementary',
  web20_product_alternative_products: 'alternativeProducts',
  web20_product_technical_drawings: 'technicalDrawings',
};
