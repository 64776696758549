import isEmpty from 'lodash.isempty';
import key from 'weak-key';
import Link from 'next/link';

// components
import BreadcrumbData from './BreadcrumbData';
import GddsBreadcrumb from './GddsBreadcrumb';

// styles
import styles from './breadcrumb.module.scss';

// utils
import { useGdds as useGDDS } from 'utils/hooks/use-gdds';
import useShowBreadcrumbs from 'utils/hooks/use-show-breadcrumbs';
import { decodingContent } from 'utils/decodingContent';

function Breadcrumb({
  items,
  className,
}: Readonly<{ items: Partial<Breadcrumb>[]; className?: string }>) {
  const isGDDS = useGDDS();
  const showBreadcrumbs = useShowBreadcrumbs();

  if (isEmpty(items)) return null;

  if (isGDDS) {
    return <GddsBreadcrumb items={items} className={className} />;
  }

  if (!showBreadcrumbs) return null;

  return (
    <nav className={`${styles.breadcrumb} ${className ?? ''} grid-container`}>
      <ol>
        {items.map((breadcrumb, index) => {
          if (isEmpty(breadcrumb)) return null;
          const isLast = index === items.length - 1;
          const isNotClickable = isEmpty(breadcrumb.url) || breadcrumb.breadcrumbDisabled === true;
          if (index === 0) {
            return (
              <li
                key={key(breadcrumb)}
                className={`${styles.breadcrumbItem} ${styles.breadcrumbItemHome}`}
              >
                <Link href={breadcrumb.url ?? ''} aria-label={breadcrumb.url}>
                  <i className={styles.breadcrumbItemHomeIcon} />
                </Link>
              </li>
            );
          }

          if (isLast || isNotClickable) {
            return (
              <li
                key={key(breadcrumb)}
                className={`${styles.breadcrumbItem} ${styles.breadcrumbItemDisabled}`}
              >
                {decodingContent(breadcrumb.label)}
              </li>
            );
          }

          return (
            <li key={key(breadcrumb)} className={styles.breadcrumbItem}>
              <Link href={breadcrumb.url ?? ''}>{decodingContent(breadcrumb.label)}</Link>
            </li>
          );
        })}
      </ol>

      <BreadcrumbData items={items} />
    </nav>
  );
}

export default Breadcrumb;
